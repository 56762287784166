html {
  background: #4c3a2d url(./assets/gamepagebacksplash1.jpg) no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 50px;
}

body {
  background-color: transparent !important;
  /* min-width: 300px; */
}

.App {
  text-align: center;
}
